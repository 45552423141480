import React from "react";
import ReactSelect, { Async } from "react-select";

import "react-select/dist/react-select.css";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.value || [],
    };
  }

  getOptions(input, callback) {
    if (input === "") {
      callback(null, { options: this.props.options });
    } else {
      const url = `${this.props.url}.json?q=${input}`;

      fetch(url, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((json) => callback(null, { options: json || [] }))
        .catch((error) => callback(error, { options: [] }));
    }
  }

  handleChange(value) {
    this.setState({
      values: value,
    });
  }

  renderInputs() {
    const name = this.props.name + "[]";

    // It needs to include an empty, to be able to clear it.
    // Otherwise it will not be included in params, so it won't be changed.
    return (
      <div>
        <input type="hidden" name={name} value="" readOnly />
        {this.state.values.map((value, _) => (
          <input
            type="hidden"
            key={value.value}
            name={name}
            value={value.value}
            readOnly
          />
        ))}
      </div>
    );
  }

  render() {
    const reactSelectProps = Object.assign({}, this.props);
    const { ajax, multi } = reactSelectProps;

    reactSelectProps.labelKey = "text";

    if (ajax !== undefined) {
      delete reactSelectProps.ajax;
      delete reactSelectProps.url;

      reactSelectProps.autoload = !!reactSelectProps.options;
      reactSelectProps.loadOptions = this.getOptions.bind(this)
    }

    if (multi === true) {
      delete reactSelectProps.name;

      reactSelectProps.value = this.state.values;
      reactSelectProps.onChange = this.handleChange.bind(this);
    }
    const component = reactSelectProps.loadOptions ? Async : ReactSelect
    const reactSelect = React.createElement(component, reactSelectProps);

    return (
      <div>
        {this.renderInputs()}
        {reactSelect}
      </div>
    );
  }
}

export default Select;
